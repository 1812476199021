!function (e, o, t) {
	var n = {
		sandbox: "https://sandbox-merchant.revolut.com/embed.js",
		prod: "https://merchant.revolut.com/embed.js",
		dev: "https://merchant.revolut.codes/embed.js"
	},
		r = {
			sandbox: "https://sandbox-merchant.revolut.com/upsell/embed.js",
			prod: "https://merchant.revolut.com/upsell/embed.js",
			dev: "https://merchant.revolut.codes/upsell/embed.js"
		},
		l = function (e) {
			var n = function (e) {
				var t = o.createElement("script");
				return t.id = "revolut-checkout",
					t.src = e, t.async = !0, o.head.appendChild(t),
					t
			}(e); return new Promise((function (e, r) {
				n.onload = function () { return e() },
					n.onerror = function () {
						o.head.removeChild(n),
						r(new Error(t + " failed to load"))
					}
			}))
		},
		u = {}, c = {}, d = function o(r, c) {
			return u[c = c || "prod"] ?
				Promise.resolve(u[c](r)) :
				l(n[c]).then((function () {
					return u[c] = window.RevolutCheckout,
						e[t] = o, u[c](r)
				}))
		};
	d.payments = function (o) {
		var r = o.mode || "prod", c =
		{
			locale: o.locale || "auto", publicToken: o.publicToken ||
				null
		}; return u[r] ? Promise.resolve(u[r].payments(c)) :
			l(n[r]).then((function () {
				return u[r] = window.RevolutCheckout,
					e[t] = d, u[r].payments(c)
			}))
	}, d.upsell = function (e) {
		var o = e.mode || "prod", t = { locale: e.locale || "auto", publicToken: e.publicToken || null };
		return c[o] ? Promise.resolve(c[o](t)) : l(r[o]).then((function () { return c[o] = window.RevolutUpsell, delete window.RevolutUpsell, c[o](t) }))
	}, e[t] = d
}(window, document, "RevolutCheckout");